<template>
  <div v-if="url" class="c-banner">
    <figure 
      class="c-banner__fig"
      :style="{ backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})` }">
      <picture class="c-banner__picture" 
        :class="{ 'c-banner__picture--full': isLandscape }">
        <img
          ref="cover"
          :src="url"
          :alt="imageAlt"
          :title="imageCredit"
          class="c-banner__img c-banner__img"
          @load="onBannerLoad"
        >
      </picture>
    </figure>
    <div class="c-banner__figcaption o-wrapper" v-if="figcaption">
      <figcaption v-if="figcaption" class="c-banner__figcaption-text">{{ figcaption }}</figcaption>
    </div>
  </div>
</template>

<script>
import { addCloudinaryUrlParams, isFullWidth } from '@/utils'

import ColorThief from '../../node_modules/colorthief/dist/color-thief.mjs'

const cloudinaryResizeParam = {
  byWidth: 'w_1920,c_scale',
  byHeight: h => `h_${h},c_scale`
}

export default {
  name: 'Banner',

  data() {
    return {
      color: [],
      isLandscape: false,
      url: null
    }
  },

  props: {
    imageUrl: String,
    imageAlt: String,
    imageCredit: String,
    imageMeta: Object,
    figcaption: String
  },

  beforeMount() {
    if (!this.imageUrl) return

    if (this.imageUrl) {
      this.url = addCloudinaryUrlParams(this.imageUrl, this.fullWidth ? cloudinaryResizeParam.byWidth : cloudinaryResizeParam.byHeight(this.getHeight()))
    }

    this.isLandscape = isFullWidth(this.imageMeta.width, this.imageMeta.height, 2.3)
  },

  methods: {
    async onBannerLoad () {
      if (!this.fullWidth) {
        const colorThief = new ColorThief()
        const img = this.$refs.cover
        img.crossOrigin = "Anonymous"
        img.width = img.naturalWidth

        try {
          this.color = colorThief.getPalette(img)[0]
        } catch(error) {
          console.warn(error)
        }
      }
    },

    getHeight() {
      const vh = window.innerHeight
      const topbarHeight = document.querySelector('.c-topbar') && document.querySelector('.c-topbar').clientHeight || 0
      const breadcrumbHeight = document.querySelector('.c-breadcrumbs') && document.querySelector('.c-breadcrumbs').clientHeight || 0
      const calc = vh - topbarHeight - breadcrumbHeight

      return calc
    }
  }
}
</script>

<style lang="scss">
.c-banner__fig {
  margin: 0;
  display: flex;
  flex-flow: column;
}
.c-banner__picture {
  width: 100%;
  text-align: center;
  height: auto;
  overflow: hidden;
  margin: 0 auto;

  @include bp(xlarge) {
    max-width: $xlarge;
    height: 100%;

    &--full {
      max-width: none;
    }
  }

  &--full {
    width: 100%;
  }
}
.c-banner__img {
  display: block;
  margin: 0;
  max-width: 100%;
  height: auto;

  .c-banner__picture--full & {
    width: 100%;
  }

  @include bp(xlarge) {
    max-height: 100%;
  }
}
.c-banner__figcaption {
  font-family: CircularPro, sans-serif;
  font-size: 0.8rem;
  max-width: calc(100% - 2rem);
  padding: .75rem 1rem;
  color: $color-grey;

  @include bp(xlarge) {
    max-width: $xlarge;
    width: $xlarge;
    padding: 0.75rem 0;
    margin: 0 auto;
  }
}
</style>
