<template>
  <div class="c-image-element-set">
    <VContentCarousel
      ref="carousel"
      class="c-image-element-set__wrapper"
      v-if="images.length"
      :auto-height="true"
      :options="{perPage: 1, loop: true}"
    >
      <ImageElement v-for="image in images" class="c-image-element-set__item" :content="image" :key="image.id" />
    </VContentCarousel>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import ImageElement from '@/components/ImageElement'
import VContentCarousel from '@forlagshuset/v-content-carousel'

export default {
  name: 'ImageElementSet',

  components: {
    VContentCarousel,
    ImageElement
  },

  setup(props, ctx) {
    const images = ref([])

    onMounted(() => {
      if (props.content.images.length) {
        images.value = props.content.images
      }
      ctx.root.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    })

    return {
      images
    }
  },

  props: {
    content: Object
  }
}
</script>

<style lang="scss">
.c-image-element-set {
  margin: 2rem 0;
  max-width: 1280px;
  width: 100%;
  clear: right;
}

.c-image-element-set__item {
  img {
    max-height: 500px;
    margin: 0 auto;
  }
}

.c-content-carousel__indicator--active {
  background: $color-brand;

  &:hover {
    background: lighten($color-brand, 10%);
  }

  .c-activity--aktor-parent-1 & {
    background: $unit-1-color-brand;
    &:hover {
      background: lighten($unit-1-color-brand, 10%);
    }
  }

  .c-activity--aktor-parent-2 & {
    background: $unit-2-color-brand;
    &:hover {
      background: lighten($unit-2-color-brand, 10%);
    }
  }

  .c-activity--aktor-parent-3 & {
    background: $unit-3-color-brand;
    &:hover {
      background: lighten($unit-3-color-brand, 10%);
    }
  }
}
</style>