<template>
  <div v-if="article" class="c-article-element">
    <Logotype class="c-article__bg u-py u-px" />
    <div class="c-article__container">
      <Banner
        v-if="cover"
        :image-url="cover"
        :image-alt="altText"
        :image-credit="coverCredit"
        :image-meta="coverMeta"
        :figcaption="caption"
      />
      <VHeading
        v-if="title"
        header="h2"
        class="c-article__title o-wrapper o-heading u-px"
      >
        <VButton
          v-if="hasTitleAudioBtn"
          class="c-article__audio-play"
          :aria-label="$t('PLAY_AUDIO')"
        >
          <AudioBlack />
        </VButton>
        {{ title }}
      </VHeading>
      <div
        v-if="description"
        class="c-article__desc o-wrapper"
        v-md-html="description"
      />
      <div
        v-if="articleBlocks.length"
        class="c-article__wrapper o-wrapper u-py u-px"
      >
        <div class="c-article__sections u-pr">
          <component
            class="c-article__section"
            v-for="(section, i) in articleBlocks"
            :key="section._id"
            :index="i"
            :is="componentNameNormalize(section.__component)"
            :content="section"
          />
        </div>
        <div v-if="concepts.length" class="c-article__concepts u-mxa">
          <VHeading
            header="h3"
            class="o-heading"
            :text="$t('CONCEPTS_HEADER')"
          />
          <component
            v-for="item in concepts"
            :key="item._id"
            :is="componentNameNormalize(item.__component)"
            :content="item"
            :can-open-modal="true"
            class="c-article__concepts-item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import VHeading from '@forlagshuset/v-heading'
import VButton from '@forlagshuset/v-button'
import { componentNameNormalize } from '@/utils'
import { ContentLoader } from 'vue-content-loader'
import AudioBlack from '@/assets/svg/AudioBlack'
import Logotype from '@/assets/svg/Logotype'
import Banner from '@/components/Banner'
import ComponentLearningElementTextBox from '@/components/TextBoxElement'
import ComponentLearningElementTextElement from '@/components/TextElement'
import ComponentLearningElementConceptElement from '@/components/ConceptElement'
import ComponentLearningElementAudioElement from '@/components/AudioElement'
import ComponentLearningElementImageElement from '@/components/ImageElement'
import ComponentLearningElementVideoElement from '@/components/VideoElement'
import ComponentLearningElementSectionTagElement from '@/components/SectionTagElement'
import ComponentLearningElementSectionEndTagElement from '@/components/SectionEndTagElement'
import ComponentLearningElementImageElementSet from '@/components/ImageElementSet'

export default {
  components: {
    AudioBlack,
    VHeading,
    VButton,
    Logotype,
    Banner,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementConceptElement,
    ComponentLearningElementAudioElement,
    ComponentLearningElementImageElement,
    ComponentLearningElementVideoElement,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementSectionEndTagElement,
    ComponentLearningElementImageElementSet,
    ContentLoader,
  },

  props: {
    article: Object,
  },

  setup(props) {
    const title = computed(() => props.article.name)
    const cover = computed(() =>
      props.article.cover ? props.article.cover.image_type.file.url : '',
    )
    const altText = computed(() =>
      props.article.cover ? props.article.cover.image_type.altText : '',
    )
    const caption = computed(() =>
      props.article.cover ? props.article.cover.image_type.description : '',
    )
    const coverCredit = computed(() =>
      props.article.cover && props.article.cover.image_type.license
        ? props.article.cover.image_type.license.credit
        : '',
    )
    const coverMeta = computed(() =>
      props.article.cover ? props.article.cover.image_type.file : '',
    )
    const description = computed(() => props.article.description)
    const articleBlocks = computed(() =>
      props.article.content
        ? props.article.content.filter(
            element =>
              componentNameNormalize(element.__component) !==
              'ComponentLearningElementConceptElement',
          )
        : '',
    )
    const concepts = computed(() =>
      props.article.content
        ? props.article.content.filter(
            element =>
              componentNameNormalize(element.__component) ===
              'ComponentLearningElementConceptElement',
          )
        : '',
    )
    const hasTitleAudioBtn = computed(
      () =>
        componentNameNormalize(articleBlocks.value[0].__component) ===
        'ComponentLearningElementAudioElement',
    )

    return {
      cover,
      coverCredit,
      coverMeta,
      altText,
      caption,
      title,
      description,
      articleBlocks,
      concepts,
      componentNameNormalize,
      hasTitleAudioBtn,
    }
  },
}
</script>

<style lang="scss">
.c-article-element {
  position: relative;
  .c-text-box-element--factbox,
  .c-text-box-element--speechbubble p:first-of-type,
  .c-text-box-element--speechbubble p:first-of-type:before,
  .c-text-box-element--discuss {
    background: $color-brand;
  }
  .c-text-box-element--against {
    background: $color-brand-light;
    .c-article__section-text {
      p:first-of-type {
        background: $color-brand-light;
      }
    }
  }
  .c-text-box-element--speechbubble {
    .c-article__section-text a {
      color: $color-black;
    }
  }
}
.c-article__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.c-article__container {
  position: relative;
  z-index: 1;
}
.c-article__wrapper {
  @include bp(1100px) {
    display: flex;
  }
}
.c-article__sections {
  @include bp(1100px) {
    width: 70%;
  }
}
.c-article__section {
  a {
    font-size: 1rem;
  }
}
.c-article__concepts {
  display: block;
  clear: both;

  @include bp(1100px) {
    width: 30%;
    padding-left: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
}
.c-article__concepts-item {
  display: flex;
}
.c-article__title {
  h2 {
    display: flex;
    align-items: center;
  }
}
.c-article__audio-play {
  display: inline-flex;
  padding: 0;
  background: none;
  margin-right: 0.9rem;
}
</style>
